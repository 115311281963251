@charset "utf-8";

.wrapper-grid-gallery-section {

	position: relative;

	.column-image {

		position: relative;
		margin-bottom: 15px;
		a {
			position: relative;
			display: block;
			&:before {
				content: "";
				position: absolute;
				width: 30%;
				height: 2px;
				bottom: -10px;
				left: 35%;
				background-color: $color-brown;
				visibility: hidden;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
				-webkit-transition: all 0.3s ease-in-out 0s;
				transition: all 0.3s ease-in-out 0s;	

				@include breakpoint(small only) {
					display: none;
				}
			}
			&:hover {
				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
		  			transform: scaleX(1);
				}
			}
		}
	}

	.headline-category-main,
	.subheadline-category-main,
	.headline-category-sub,
	.caption-bottom-image {

		color: $color-brown;
		text-align: center;
	}

	.headline-category-main {

		@include font-size(45px);
	}

	.subheadline-category-main {

		@include font-size(28px);
		font-weight: $medium;
	}

	.headline-category-sub {

		@include font-size(35px);
		padding-top: 40px;
	}

	.caption-bottom-image {

		@include font-size(20px);
	}

	.img-section-left,
	.img-section-right {

		position: absolute;
		width: 300px;
		height: auto;

		@include breakpoint(medium down) {

			display: none;
		}

		@include breakpoint(large only) {

			width: 200px;
			
			@include breakpoint(portrait) {

				display: none;
			}
		}
	}

	.img-section-left {

		left: 0;
		bottom: 20%;
	}

	.img-section-right {

		right: 0;
		top: 20%;
	}

	.wrapper-grid-gallery-headline {

		.headline,
		.subheadline {

			text-align: center;
			color: $color-brown;
		}
	}
}

.box-batch-new-product {

	position: absolute;
	top: 0;
	right: 5%;

	.img-batch-new-product {

		width: 105px;
		height: auto;

		@include breakpoint(large only) {
			@include breakpoint(landscape) {
				width: 75px;
			}
		}

		@include breakpoint(medium only) {

			width: 85px;
		}

		@include breakpoint(small only) {

			width: 45px;
		}
	}

	&.detailpage {

		top: 7%;
		right: 10%;

		.img-batch-new-product {

			width: 144px;
			height: auto;
		}

		@include breakpoint(large only) {
			@include breakpoint(landscape) {
				top: 3%;
				right: 7%;
			}
		}

		@include breakpoint(small only) {

			top: 0;
			right: 8%;

			.img-batch-new-product {

				width: 90px;
			}
		}

		@include breakpoint(medium only) {

			right: 1%;
			top: 1%;

			.img-batch-new-product {

				width: 140px;
			}
		}
	}

	&.bottomslider {

		.img-batch-new-product {

			@include breakpoint(small only) {

				width: 85px;
			}
		}
	}
}
