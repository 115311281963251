@charset "utf-8";

@include breakpoint(small up) {

	.wrapper-menu-footer {
		// padding-top: 20px;
		// padding-bottom: 20px;
		// text-align: center;

		a {

			@include font-size($font-size-menu-main);
			font-family: $font-family-menu-main;
			font-weight: $medium;

			@include breakpoint(medium only) {

				@include font-size($font-size-menu-main-medium);
			}
			position: relative;
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $color-white;
				visibility: hidden;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
				-webkit-transition: all 0.3s ease-in-out 0s;
				transition: all 0.3s ease-in-out 0s;	
			}
			&:hover {
				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
		  			transform: scaleX(1);
				}
			}
		}

		// UL first level:
		.nav-main {

			position: relative;
			list-style: none;
			margin: 0;
			padding: 0;
		}

		// LI first level:
		.nav-main-item {

			position: relative;
			display: block;
			margin: 0;
			cursor: pointer;

			&.current-menu-item,
			&.current_page_item,
			&.current-menu-ancestor,
			&.current-menu-item,
			&.current-menu-parent,
			&.current-page-ancestor {

				> a {

					// color: $color-lila-dark;
					font-weight: $heavy;
				}
			}

			&:hover {

				> a {

					color: $color-menu-main-hover;
				}
			}

			//&:last-of-type {
			&:last-child {

				margin-right: 0;
			}
		}

		// A HREF first level:
		.nav-main-link {

			display: inline-block;
			padding-top: 10px;
			padding-bottom: 10px;
			color: $color-menu-main-normal;
			line-height: 1;
			text-decoration: none;
		}

		// @include breakpoint(medium only) {

		// 	@include breakpoint(portrait) {

		// 	}

		// 	@include breakpoint(landscape) {

		// 	}
		// }

	}
}
