@charset "utf-8";

.wrapper-teaser,
.wrapper-teaser-finish {
	position: relative;
	z-index: 1;
	.on-left-narrow {
		transform: skew(0deg, $teaser-screw-angle-neg);
		.unskew {
			transform: skew(0deg, $teaser-screw-angle-pos);
		}
	}
	.on-left-tall {
		transform: skew(0deg, $teaser-screw-angle-pos);
		.unskew {
			transform: skew(0deg, $teaser-screw-angle-neg);
		}
	}
}

.wrapper-teaser {
	&.teaser-type-left-aligned {
		.teaser-scale {
			min-height: $teaser-min-height-left-alligned;	// 720px (zumindest beim beim Head Teaser)
			padding-top: $teaser-content-top-padding;
      padding-bottom: $teaser-content-bottom-padding;
		}
	}

	&.teaser-ueber-alpia-flex {
		.teaser-scale {
			padding-top: $teaser-content-top-padding / 2;
      padding-bottom: $teaser-content-bottom-padding / 2;

      @include breakpoint(large) {
        padding-top: $teaser-content-top-padding;
        padding-bottom: $teaser-content-bottom-padding;
      }

			div {
				.medium-order-1.col-image {
					text-align: right;
				}
			}
		}
	}
	&.teaser-type-centered,
	&.teaser-type-tag,
	&.teaser-type-zutaten {
		.headline,
		.subheadline {
			text-align: center;
		}
		p {
			text-align: center;
		}
	}

	&.teaser-type-centered {
		.teaser-scale {
			min-height: $teaser-min-height-centered;
			padding-top: $teaser-content-top-padding / 3;
      padding-bottom: $teaser-content-bottom-padding;

      @include breakpoint(large) {
        padding-top: $teaser-content-top-padding;
      }

			@include breakpoint(large only) {
				@include breakpoint(landscape) {
					padding-bottom: 110px;
				}
			}
			@include breakpoint(medium only) {
				padding-bottom: 110px;
			}
		}
	}

	&.teaser-type-tag {
		margin-top: $tag-teaser-shift-to-top;
		.teaser-scale {
			min-height: $teaser-min-height-left-alligned;
			padding-top: $teaser-content-top-padding;
			padding-bottom: $teaser-content-bottom-padding;
		}
	}

	&.teaser-type-zutaten {
		margin-top: $zutaten-teaser-margin-top;
		.teaser-scale {
			min-height: $teaser-min-height-zutaten;
			padding-top: $teaser-content-zutaten-top-padding;
			padding-bottom: $teaser-content-zutaten-bottom-padding * 2;
		}
	}

	&.shift-to-top {
		margin-top: $teaser-shift-to-top;
		@include breakpoint(small only) {
			margin-top: -50px;
		}
	}

	&.top-teaser {
		.teaser-scale {
			min-height: $teaser-min-height-top;
			@include breakpoint(small only) {
				padding-bottom: 60px;
			}
			@include breakpoint(medium only) {
				min-height: 540px;
			}
			@include breakpoint(large only) {
				@include breakpoint(landscape) {
					min-height: 700px
				}
			}
			@include breakpoint(xlarge) {
				min-height: 610px;
			}
			@include breakpoint(xxlarge) {
				min-height: 720px;
			}
			@include breakpoint(xxxlarge) {
				min-height: 800px;
			}
		}

		&.shift-to-top {
			margin-top: $teaser-shift-to-top;
			@include breakpoint(small only) {
				margin-top: $teaser-shift-to-top-first-small;
			}
		}

		.headline {
			&.buttonLila {
				color: $color-lila-dark;
			}
			&.buttonWhite {
				color: $color-white;
			}
			&.buttonBrown {
				color: $color-brown;
			}
		}

		p {
			color: $color-brown;
		}

		.align-bottom-on-small {
			@include breakpoint(small only) {
				align-self: flex-end;
				padding-bottom: 50px;
			}
		}
	}

	.headline {
		margin-bottom: 10px;
		@include breakpoint(small only) {
			padding-top: 12px;
		}
		@include breakpoint(large only) {
			@include breakpoint(landscape) {
				margin-top: 40px;
			}
		}
		@include breakpoint(medium only) {
			margin-top: 40px;
		}
	}

	p {
		@extend %txt-format-h6;
		line-height: 30px;
		color: $color-white;
		@include breakpoint(small only) {
			line-height: 30px;
		}
	}

	.box-readmore {
		display: block;
		padding-top: 10px;
		text-align: center;
		margin-bottom: 0;
	}

	.topicon {
		position: absolute;
		top: 0;
		left: 49.5%;
		transform: translate(-50%, -50%);
		z-index: 4;
    }

	.background-container {
		position: relative;
		color: $color-white;
		font-weight: 700;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;

		@include breakpoint(small only) {
			background-size: cover;
			overflow: hidden;
			.img-teaser-small {
				margin-top: -105px;
			}
		}

		&.background-parallax {
			background-attachment: fixed;
			transform: unset;
			.unskew {
				div {
					div.teaser-content {
						transform: skew(0deg, -1deg)!important;
					}
				}
			}
		}
	}

	.columns {
		&.small-no-pading-column {
			@include breakpoint(small only) {
				padding: 0;
			}
		}
	}
}

.wrapper-teaser-finish {
	position: relative;
	margin-top: $teaser-finish-shift-to-top;
	.background-container,
	.box-final-rect {
		position: relative;
	}

	.background-container {
		height: 60px;
	}

	.box-final-rect {
		height: 40px;
		margin-top: $teaser-shift-to-top;
	}
}

.bg-color-transparent {
	background-color: transparent;
}

.bg-white {
	background-color: $color-white;
}

.bg-helles-rosa {
	background-color: $color-helles-rosa;
}

.bg-helles-rosa-article {
	background-color: $color-helles-rosa-article;
}

.bg-rosa {
	background-color: $color-rosa;
}

.bg-color-cta-lila-dark {
	background-color: $color-lila-dark;
}

.bg-color-category-pur {
	background-color: $color-tag-cat-pur;
}

.bg-color-category-nussig {
	background-color: $color-tag-cat-nussig;
}

.bg-color-category-knusprig {
	background-color: $color-tag-cat-knusprig;
}

.bg-color-category-cremig {
	background-color: $color-tag-cat-cremig;
}

.bg-color-category-fruchtig {
	background-color: $color-tag-cat-fruchtig;
}

.bg-color-category-salzig {
  background-color: $color-tag-cat-salzig;
}

.bg-erdbeer-joghurt {
	background-color: $color-kat-tafel-erdbeer-joghurt;
}

.bg-color-zutaten {
	background-color: $color-pink;
}

.bg-color-paddington-grey {
	background-color: #ECECEC;
}

.bg-color-paddington-yellow {
	background-color: #F4C11B;
}

.bg-color-paddington-blue {
	background-color: #033964;
}

.bg-color-paddington-light-blue {
	background-color: #4171C3;
}

.bg-color-paddington-purple {
	background-color: #771E68;
}

// button classes
.btn-read-more {
	@extend %btn-read-more;
	position: relative;

	&:after {
		content: "";
		width: 0%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: -1;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
	}
	&:hover {
		&:after {
			width: 100%;
		}
	}

	&.buttonLila {
		color: $color-lila-dark;
		border-color: $color-lila-dark;
		&:after {
			background: $color-lila-dark;
		}
		&:hover {
			background-color: $color-lila-dark;
			color: $color-white;
			border-color: $color-lila-dark;
		}
	}

	&.buttonWhite {
		color: $color-white;
		border-color: $color-white;
		&:after {
			background: $color-white;
		}
		&:hover {
			background-color: $color-white;
			color: $color-lila-dark;
			border-color: $color-white;
		}
	}

	&.buttonBrown {
		color: $color-brown;
		border-color: $color-brown;
		&:after {
			background: $color-brown;
		}
		&:hover {
			background-color: $color-brown;
			color: $color-white;
			border-color: $color-brown;
		}
	}

	@include breakpoint(small only) {
		display: block;
		margin-bottom: $margin-bottom-readmore-small;
	}
}

// headline & copy color classes teaser
.teaser-content {
	&.buttonLila {
		.headline, p {
			color: $color-lila-dark;
		}
	}
	&.buttonWhite {
		.headline, p {
			color: $color-white;
		}
	}
	&.buttonBrown {
		.headline, p {
			color: $color-brown;
		}
	}
}
