@import 'base/foundation-includes';
// @import '~jquery.mmenu/dist/jquery.mmenu.all.css';
// @import '~sass-burger/burger';
@import '~swiper/dist/css/swiper.css';

// $fa-font-path: '~font-awesome/fonts';
// @import '~font-awesome/scss/font-awesome';

@import 'base/wp-core-styles';

@import 'base/mixin';

@import 'base/settings';
@import 'base/typography';
@import 'base/reset';
@import 'base/base';
@import 'base/base_classes';

@import 'partials/layout';
@import 'partials/gallery_section';
@import 'partials/teaser';
@import 'partials/product_archive';
@import 'partials/menu';
@import 'partials/menu_footer';
@import 'partials/flexcontent';

@import 'partials/media_queries';
