@charset "UTF-8";

html {
	font-size: 100%;
	height: 100%;
	min-height: 100%;
}

body {
	height: 100%;
	min-height: 100%;
	background: $background-color-body;
	color: $font-color-body;
	font-family: $font-family-body;
	font-style: normal;
  font-weight: $regular;
  overflow-x: hidden;
}

#root {
	background-image: url('./../images/logo.png');
	width: 200px;
	height: 200px;
}

strong,
b {
	font-weight: $bold;
}

i,
em {
	font-style: italic;
}

p {
	//line-height: $line-height-body-large;
	margin-bottom: 1rem;
}

img {
	max-width: 100%;
	height: auto;
	-ms-interpolation-mode: bicubic;
	display: inline-block;
	vertical-align: middle;
}

a {
	color: $color-href;
	text-decoration: none;
	//position: relative;

	&:hover {
		color: $color-href;
	}
}

// mobile phones
a[href^='tel']:link,
a[href^='tel']:visited,
a[href^='tel']:hover {
	text-decoration: none;
	color: $color-href;
}

h1,
h2,
h3,
h4,
%txt-format-h5,
%txt-format-h6,
%txt-format-h7,
%txt-format-h8 {

	// @extend %font-family-brandon-black;
	font-family: $font-family-brandon;
	font-style: normal;
}

h1 {

	// Topsection Headline
	@include font-size($font-size-h1-large);
	// line-height: $line-height-h1-large;	// scheint zu hoch angesetzt zu sein
	line-height: 77px;
	font-weight: $font-weight-h1-large;

	p {
		line-height: 77px;
		margin-bottom: 0;
	}

	@include breakpoint(small only) {
		@include font-size($font-size-h1-small);
		line-height: $line-height-h1-small;
	}

	@include breakpoint(large only) {
		@include breakpoint(landscape) {
			@include font-size($font-size-h1-medium);
			line-height: $line-height-h1-medium;
		}
	}

	@include breakpoint(medium only) {
		@include font-size($font-size-h1-medium);
		line-height: $line-height-h1-medium;
	}
}

h2 {

	// Teaser Headline
	@include font-size($font-size-h2-large);
	line-height: $line-height-h2-large;
	font-weight: $font-weight-h2-large;

	@include breakpoint(large only) {
		@include breakpoint(landscape) {
			@include font-size($font-size-h1-medium);
			line-height: $line-height-h1-medium;
		}
	}

	@include breakpoint(medium only) {
		@include font-size($font-size-h1-medium);
		line-height: $line-height-h1-medium;
	}

	@include breakpoint(small only) {
		@include font-size($font-size-h1-small);
		line-height: $line-height-h1-small;
	}
}

h3 {

	// Subline
	@include font-size($font-size-h3-large);
	line-height: $line-height-h3-large;
	font-weight: $font-weight-h3-large;
}

h4 {

	// Subsubline
	@include font-size($font-size-h4-large);
	line-height: $line-height-h4-large;
	font-weight: $font-weight-h4-large;
}

%txt-format-h5 {

	// Copy Topsection
	@include font-size($font-size-h7-large);
	line-height: $line-height-h7-large;
	font-weight: $font-weight-h5-large;
}

%txt-format-h6 {

	// Copy Teaser, Filter
	@include font-size($font-size-h7-large);
	line-height: $line-height-h7-large;
	font-weight: $font-weight-h5-large;
}

%txt-format-h7 {

	// Produktnamen
	@include font-size($font-size-h7-large);
	line-height: $line-height-h7-large;
	font-weight: $font-weight-h7-large;
}

%txt-format-h8 {

	// Artikeldetail Copy
	@include font-size($font-size-h7-large);
	line-height: $line-height-h7-large;
	font-weight: $font-weight-h5-large;
	font-style: italic;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

%btn-read-more {

	@include font-size(15px);
	font-weight: $heavy;
	color: $color-lila-dark;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	background-color: transparent;
	padding: 12px 32px;
	border-style: solid;
	border-width: 2px;
	border-radius: 5px;
	border-color: $color-lila-dark;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;

	&:hover {

		background-color: $color-lila-dark;
		color: $color-white;
		border-color: $color-lila-dark;
	}
}

.burger-button {
	display: inline-block; // !important
	position: absolute;
	top: 0px;
	right: 10px;
	z-index: 999999;

	@include breakpoint(large) {
		display: none;
	}

	.menu-button {

		display: inline-block;
		padding: .5em;

		width: 34px;
		height: 45px;
		position: relative;
		margin: 15px auto;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		cursor: pointer;

		span {
			display: block;
		  	position: absolute;
		  	height: 3px;
		  	width: 50%;
		  	background: $color-burger-button;
		  	opacity: 1;
		  	-webkit-transform: rotate(0deg);
		  	-moz-transform: rotate(0deg);
		  	-o-transform: rotate(0deg);
		 	transform: rotate(0deg);
		  	-webkit-transition: .25s ease-in-out;
		  	-moz-transition: .25s ease-in-out;
		  	-o-transition: .25s ease-in-out;
		  	transition: .25s ease-in-out;

		  	&:nth-child(even) {
			  	left: 50%;
			  	border-radius: 0 3px 3px 0;
			}
			&:nth-child(odd) {
			  	left:0px;
			  	border-radius: 3px 0 0 3px;
			}
			&:nth-child(1),
			&:nth-child(2) {
				top: 0;
			}
			&:nth-child(3),
			&:nth-child(4) {
				top: 8px;
			}
			&:nth-child(5),
			&:nth-child(6) {
				top: 16px;
			}
		}
		&.open {
			span {
				&:nth-child(1),
				&:nth-child(6) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&:nth-child(2),
				&:nth-child(5) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
				&:nth-child(1) {
					left: 1px;
					top: 7px;
				}
				&:nth-child(2) {
					left: calc(50% - 5px);
						top: 7px;
				}
				&:nth-child(3) {
					left: -50%;
  					opacity: 0;
				}
				&:nth-child(4) {
					left: 100%;
  					opacity: 0;
				}
				&:nth-child(5) {
					left: 2px;
  					top: 17px;
				}
				&:nth-child(6) {
					left: calc(50% - 6px);
  					top: 17px;
				}
			}
		}
	}
}

.mm-opened {

	.burger-button {

		right: auto;
		left: 10px;
	}
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-white;;
	z-index: 999;

	.icon-loading {
		@include center-block(250px);
		display: block;
		padding-top: 35vh;

		@include breakpoint(small only) {
			@include center-block(100px);
		}
	}
}

.cookie-notice-container {
	padding: 20px 60px;
	text-align: left;
	font-size: 14px;
	line-height: 20px;
	span {
		display: block;
		margin-bottom: 20px;
	}
	a {
		margin-left: 0!important;
		margin-right: 10px;
	}
}
