// Source: http://scotch.io/

@mixin font-size( $size ) {
	font-size: $size;
	font-size: rem-calc( $size );
}

@mixin border-radius ($radius: 4px) {
	border-radius:          $radius;
	-moz-border-radius:     $radius;
	-webkit-border-radius:  $radius;
}
@mixin border-radius-all ($topleft: 4px, $topright: 4px, $bottomright: 4px, $bottomleft: 4px) {
	border-radius:          $topleft $topright $bottomright $bottomleft;
	-moz-border-radius:     $topleft $topright $bottomright $bottomleft;
	-ms-border-radius:      $topleft $topright $bottomright $bottomleft;
	-o-border-radius:       $topleft $topright $bottomright $bottomleft;
	-webkit-border-radius:  $topleft $topright $bottomright $bottomleft;
}
@mixin opacity ($opacity: .5) {
	$ie-opacity:       $opacity * 100;
	opacity:          $opacity;
	-moz-opacity:     $opacity;
	-webkit-opacity:  $opacity;
	-ms-filter:       progid:DXImageTransform.Microsoft.Alpha(opacity=#{$ie-opacity}); /* IE 8 */
	filter:           alpha(opacity = #{$ie-opacity}); /* IE 5-7 */
}
@mixin background-alpha($color, $alpha) {
	$acolor:  hsla(hue($color), saturation($color), lightness($color), $alpha);
	$iecolor: argb($acolor);
	background-color: $color; /* fallback color */
	background-color: $acolor;

	/* IE */
	background-color: transparent\9;
	filter:           progid:DXImageTransform.Microsoft.gradient(startColorstr= #{$iecolor}, endColorstr= #{$iecolor});
	zoom:             1;
}
@mixin box-shadow ($x-size:10px, $y-size:10px, $blur:5px, $color: $black, $spread:0) {
	box-shadow:         $x-size $y-size $blur $spread $color;
	-moz-box-shadow:    $x-size $y-size $blur $spread $color;
	-webkit-box-shadow: $x-size $y-size $blur $spread $color;
}
@mixin box-shadow-inset ($x-size: 10px, $y-size: 10px, $blur: 5px, $color: $black, $spread:0) {
	box-shadow:         inset $x-size $y-size $blur $spread $color;
	-moz-box-shadow:    inset $x-size $y-size $blur $spread $color;
	-webkit-box-shadow: inset $x-size $y-size $blur $spread $color;
}
@mixin gradient-vertical ($first-color: #ffffff, $second-color: #dddddd) {
	background: $first-color; /* Fallback solid color for old browsers */
	background: linear-gradient(to bottom, $first-color 0%, $second-color 100%); /* W3C */
	background: -moz-linear-gradient(top, $first-color 0%, $second-color 100%); /* FF 3.6+ */
	background: -ms-linear-gradient(top, $first-color 0%, $second-color 100%); /* IE 10+ */
	background: -o-linear-gradient(top, $first-color 0%, $second-color 100%); /* Opera 11.10+ */
	background: -webkit-linear-gradient(top, $first-color 0%,$second-color 100%); /* Chrome 10+, Safari 5.1+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$first-color), color-stop(100%,$second-color)); /* Chrome, Safari 4+ */
	filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$first-color}', endColorstr= '#{$second-color}', GradientType=0); /* IE 6-9 */
}
@mixin gradient-horizontal ($first-color: #ffffff, $second-color: #dddddd) {
	background: $first-color; /* Fallback solid color for old browsers */
	background: linear-gradient(to right, $first-color 0%, $second-color 100%); /* W3C */
	background: -moz-linear-gradient(left,  $first-color 0%, $second-color 100%); /* FF 3.6+ */
	background: -ms-linear-gradient(left, $first-color 0%, $second-color 100%); /* IE 10+ */
	background: -o-linear-gradient(left, $first-color 0%, $second-color 100%); /* Opera 11.10+ */
	background: -webkit-linear-gradient(left,  $first-color 0%,$second-color 100%); /* Chrome 10+, Safari 5.1+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,$first-color), color-stop(100%,$second-color)); /* Chrome, Safari 4+ */
	filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr= '#{$first-color}', endColorstr= '#{$second-color}', GradientType=1); /* IE 6-9 */
}
@mixin transition ($type:all, $time:.2s, $ease:ease) {
	transition:         $type $time $ease;
	-moz-transition:    $type $time $ease;
	-webkit-transition: $type $time $ease;
	-o-transition:      $type $time $ease;
}
@mixin animation ($name, $duration: 300ms, $delay: 0, $ease: ease) {
	-moz-animation:     $name, $duration, $delay, $ease;
	-ms-animation:      $name, $duration, $delay, $ease;
	-webkit-animation:  $name, $duration, $delay, $ease;
}
@mixin transform ($deg) {
	transform:          $deg;
	-moz-transform:     $deg;
	-ms-transform:      $deg;
	-o-transform:       $deg;
	-webkit-transform:  $deg;
}
@mixin scale ($multiplier) {
	transform:          scale($multiplier);
	-moz-transform:     scale($multiplier);
	-ms-transform:      scale($multiplier);
	-o-transform:       scale($multiplier);
	-webkit-transform:  scale($multiplier);
}
@mixin rotate ($deg) {
	-moz-transform:     rotate($deg);
	-ms-transform:      rotate($deg);
	-o-transform:       rotate($deg);
	-webkit-transform:  rotate($deg);
}
@mixin skew ($deg, $deg2) {
	transform:          skew($deg, $deg2);
	-moz-transform:     skew($deg, $deg2);
	-ms-transform:      skew($deg, $deg2);
	-o-transform:       skew($deg, $deg2);
	-webkit-transform:  skew($deg, $deg2);
}

@mixin center-block ($max-width: 1600px) {

	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
}
