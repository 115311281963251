$font-path:             '../fonts' !default;
$img-path:              '../images' !default;
$font-size-base:        16px !default;
$rem-base:              16px !default;
$line-height-base:       1 !default;

// color
$color-bg-base:       #353f48 !default;
$color-bg-dark:       #000000 !default;
$color-bg-dark-alpha: rgba(0, 0, 0, .7) !default;
$color-bg-ci:         #16416e !default;
$color-font-light:    #ffffff !default;
$color-href:          rgba(155, 155, 155, .5) !default;

$color-burger-button: #ffffff;

// CI COLORS ALPIA:
// -------------------------------------------
// Primärfarben:
$color-white: #ffffff;  // Typografie:
$color-brown: #3a0400;  // Typografie:

$color-pink: #c531a5;
$color-pink-panter: #e14dbd;
$color-rosa: #ff69d5;
// $color-helles-rosa: #ffcff4;     // ???
$color-helles-rosa: #ffe7f9;      // ???
$color-helles-rosa-article: #fff5fd;
$color-lila-dark: #621d92;
$color-lila-light: #9160b2;
$color-stoerer: #e0bb6a;

// Kategorien
// Tafelschokoladen
$color-kat-tafel-alpenvollmilch: #24beee;
$color-kat-tafel-haselnuss: #9ed34f;
$color-kat-tafel-weisse: #eed9c1;
$color-kat-tafel-noisette: #c79055;
$color-kat-tafel-traube-nuss: #9ed34f; // Farbe in PSD anders
$color-kat-tafel-schokolinsen: #eed9c1;
$color-kat-tafel-knusprige-weisse: #f3e4a6;
$color-kat-tafel-edel-marzipan: #f0533f;
$color-kat-tafel-zartbitter: #7a4e44;
$color-kat-tafel-edel-nougat: #5e5184;
$color-kat-tafel-erdbeer-joghurt: #efabca;
$color-kat-tafel-kokos: #80c2cd;
$color-kat-salzig: #22B801;

// Schokosnacks
$color-kat-schokosnacks-flakes-erdnuesse: #24beee;
$color-kat-schokosnacks-flakes-crisp-mais: #ffef34;
$color-kat-schokosnacks-flakes-kokos: #a6e6fd;
$color-kat-schokosnacks-schoko-kornflakes: #ffca1a;
$color-kat-schokosnacks-fruties: #efabca; // Farbe in PSD anders
$color-kat-schokosnacks-alpinos: #3bade8;

// Schokolinos
$color-kat-schokolinos-haselnuss: #95e84c;
$color-kat-schokolinos-mandel: #efabca;

// Schokospaß
$color-kat-schokospass-zoo: #7c411a;
$color-kat-schokospass-baeren: #7c411a;
$color-kat-schokospass-bonbons: #68b53e;

// Kategoriefarben für die TAG Seiten
$color-tag-cat-pur: $color-kat-tafel-alpenvollmilch;
$color-tag-cat-nussig: $color-kat-tafel-haselnuss;
$color-tag-cat-knusprig: $color-kat-tafel-schokolinsen;
$color-tag-cat-cremig: $color-kat-tafel-edel-marzipan;
$color-tag-cat-fruchtig: $color-kat-tafel-erdbeer-joghurt;
$color-tag-cat-salzig: $color-kat-salzig;


// -------------------------------------------

// $max-width:            680;

// FONT WEIGHTS
// -------------------------------------------
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$heavy: 900;        // 'black'

// FONT FAMILIES
// -------------------------------------------
$font-family-sans-serif: 'Verdana', sans-serif;
$font-family-source-sans-pro: 'Source Sans Pro', sans-serif;
$font-family-brandon: 'Brandon', sans-serif;

// FONT SIZES
// -------------------------------------------
$font-style-h1: normal;
$font-size-h1-large: 69px;
$line-height-h1-large: 97px;
$font-weight-h1-large: $heavy;

$font-size-h1-medium: 42px;
$line-height-h1-medium: 48px;

$font-size-h1-small: 35px;
$line-height-h1-small: 48px;

// -------------------------------------------

$font-style-h2: normal;
$font-size-h2-large: 45px;
$line-height-h2-large: 53px;
$font-weight-h2-large: $bold;

// -------------------------------------------

$font-style-h3: normal;
$font-size-h3-large: 36px;
$line-height-h3-large: 44px;
$font-weight-h3-large: $heavy;

// -------------------------------------------

$font-style-h4: normal;
$font-size-h4-large: 24px;
$line-height-h4-large: normal;
$font-weight-h4-large: $medium;

// -------------------------------------------

$font-style-h5: normal;
$font-size-h5-large: 19px;
$line-height-h5-large: 49px;
$font-weight-h5-large: $medium;

// -------------------------------------------

$font-style-h6: normal;
$font-size-h6-large: 17px;
$line-height-h6-large: 42px;
$font-weight-h6-large: $medium;

// -------------------------------------------

$font-style-h7: normal;
$font-size-h7-large: 20px;
$line-height-h7-large: 45px;
$font-weight-h7-large: $heavy;

// -------------------------------------------

$font-style-h8: italic;
$font-size-h8-large: 17px;
$line-height-h8-large: 42px;
$font-weight-h8-large: $medium;

// BASICS
// -------------------------------------------
$background-color-body: $white;

// BASE FONT
// -------------------------------------------
$font-family-body: $font-family-brandon;
$font-color-body: $black;

// MENU
// -------------------------------------------
$font-family-menu-main: $font-family-brandon;

$font-size-menu-main: 20px;
$font-size-menu-main-medium: 20px;

$color-menu-main-normal: $white;
$color-menu-main-hover: $white;

$menu-bg-drop: $white;
$menu-bg-drop-opacity: .8;

$padding-top-menu-main-large: 36px;
$padding-top-menu-main-large-landscape: 15px;
$padding-top-menu-main-medium: 15px;	// ex 40px

$padding-left-offset-center-nav-medium-only: 220px;
$padding-left-offset-center-nav-large-landscape: 290px;	// tablet landscape

// Teaser
// -------------------------------------------
$teaser-screw-angle-pos: 1deg;
$teaser-screw-angle-neg: -1deg;

$teaser-shift-to-top: -34px;
//$teaser-shift-to-top-first: -50px;
$teaser-shift-to-top-first: -120px;
$teaser-shift-to-top-first-small: -120px;

// $teaser-min-height-top: 720px;
$teaser-min-height-top: 800px;
$teaser-min-height-left-alligned: 650px;
$teaser-min-height-centered: 400px;

// $teaser-content-top-padding: 60px;
$teaser-content-top-padding: 100px;
$teaser-content-bottom-padding: 100px;

// Teaser finish:
$teaser-finish-shift-to-top: -46px;

// TAG Seite
$tag-teaser-shift-to-top: 60px;

// TAG Section Teaser auf Produkt Detailseite
$teaser-min-height-zutaten: 300px;
$teaser-content-zutaten-top-padding: 60px;
$teaser-content-zutaten-bottom-padding: 60px;
$zutaten-teaser-margin-top: 80px;

// Read more Button
$margin-bottom-readmore-small: 40px;

// Produkt Archiv
// -------------------------------------------
$prod-archive-screw-angle-pos: $teaser-screw-angle-pos;
$prod-archive-screw-angle-neg: $teaser-screw-angle-neg;

$prod-archive-shift-to-top: $teaser-shift-to-top;
$prod-archive-shift-to-top-first: -80px;

$prod-archive-head-min-height: 580px;



// Mixed
// -------------------------------------------
$slider-nav-item-border-bottom-top: 30px;
$slider-nav-item-border-left-right: 30px;

$width-product-back-box: 152px;
$prod-back-btn-border-bottom-top: 21px;
$prod-back-btn-border-left-right: $prod-back-btn-border-bottom-top;
$prod-back-arrow-font-size: 20px;
$prod-back-btn-label-font-size: 14px;
$prod-back-btn-label-line-height: $prod-back-btn-border-bottom-top * 2;	// regelt die Höhe des labels und damit die Hintergrundfarbe

$width-product-back-box-small: 100px;
$prod-back-btn-border-bottom-top-small: 24px;
$prod-back-btn-border-left-right-small: $prod-back-btn-border-bottom-top-small;
$prod-back-arrow-font-size-small: 20px;
$prod-back-btn-label-font-size-small: 14px;
$prod-back-btn-label-line-height-small: $prod-back-btn-border-bottom-top-small * 2;	// regelt die Höhe des labels und damit die Hintergrundfarbe
