@charset "utf-8";

@include breakpoint(small only) {
	/* bis max 640px */
	/* .burger-button {

		display: inline-block;
	} */

	.mm-opened {

		.burger-button {

			display: block;
		}
	}

	#menu {

		//display: none;
	}
}

@include breakpoint(small up) {
	/* von kleinst bis unendlich */

}

@include breakpoint(medium only) {
	/* min 641 bis max 1023 */

}

@include breakpoint(medium up) {
	/* min 641 bis unendlich */

}

@include breakpoint(large up) {
	/* min 1024 bis unendlich */

}


// OWN MEDIA QUERIES:
/*
@media screen and #{breakpoint(medium)} {
  // Medium and up styles
}
@media screen and #{breakpoint(medium)} and #{breakpoint(xlarge down)} {
  // Medium to extra large styles
}
*/
