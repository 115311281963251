@charset "utf-8";

.flexcontent {

	&.title-copytext-image-2-cols {

		//margin-bottom: 40px;

		@include breakpoint(medium only) {
			margin-top: 0;
		}

		@include breakpoint(small only) {
			margin-top: 0;
		}

		.headline,
		p {

			color: $color-brown;
		}

		.headline {

			margin-bottom: 4px;

			@include breakpoint(medium only) {

				@include font-size(36px);
			}
		}

		p {

			@extend %txt-format-h8;
			line-height: 28px;

			@include breakpoint(medium only) {

				@include font-size(16px);
				line-height: 25px;
			}
		}
	}
}
