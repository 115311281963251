@charset "utf-8";


// parent element must have a value for 'position':
%base-centered-icon {

	color: transparent;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	bottom: 50%;
	z-index: 2;
	overflow: hidden;
	margin: auto;
	left: 0;
	right: 0;
}

%base-read-more {

	@include font-size(15px);
	font-weight: $semi-bold;
	color: $color-href;
}

// Brandon font-family:
%font-family-brandon-thin-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 100;
}

%font-family-brandon-thin {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 100;
}

%font-family-brandon-light-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 300;
}

%font-family-brandon-light {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 300;
}

%font-family-brandon-regular-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 400;
}

%font-family-brandon-regular {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 400;
}

%font-family-brandon-medium-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 500;
}

%font-family-brandon-medium {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 500;
}

%font-family-brandon-bold-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 700;
}

%font-family-brandon-bold {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 700;
}

%font-family-brandon-black-italic {

	font-family: $font-family-brandon;
	font-style: italic;
	font-weight: 900;
}

%font-family-brandon-black {

	font-family: $font-family-brandon;
	font-style: normal;
	font-weight: 900;
}

/* usage example:
.other-class {
	@extend %base-centered-icon;
	background: transparent url('background-image.jpg') no-repeat;
}
*/
