@charset "utf-8";

.archive-produkte {

	padding-bottom: 140px;
}

.wrapper-product-archive-head {

	@include center-block(1900px);
	margin-top: -60px;
	position: relative;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1;

	.teaser-scale {

		min-height: $prod-archive-head-min-height;
	}

	.headline,
	.subheadline {

		color: $color-brown;
		text-align: center;
	}

	.subheadline {

		@include breakpoint(small only) {

			@include font-size(40px);
		}
	}

	.box-term-buttons {

		padding-top: 30px;
		text-align: center;
	}

	.btn-term,
	.btn-anchor {

		@include font-size(14px);
		font-weight: $bold;
		text-decoration: none;
		display: inline-block;
		text-align: center;
		padding: 8px 8px;
		margin-bottom: 5px;
		border-style: solid;
		border-width: 2px;
		border-radius: 3px;
		text-transform: uppercase;
	}

	.btn-term {

		color: $color-lila-dark;
		background-color: transparent;
		border-color: $color-lila-dark;
		outline: none;
		position: relative;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;

		&:after {
			content: "";
			width: 0%;
			height: 100%;
			top: 0;
			left: 0;
			background: $color-lila-dark;
			position: absolute;
			z-index: -1;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
    }

    &.new-products{
      border-color: $color-kat-salzig;
      color: $color-kat-salzig;
      &:after {
        background: $color-kat-salzig;
      }
      &:hover,
      &.is-active {
        background-color: $color-kat-salzig;
			  color: $color-white;
        border-color: $color-kat-salzig;
      }
    }

		&:hover,
		&.is-active {
			background-color: $color-lila-dark;
			color: $color-white;
      border-color: $color-lila-dark;

			&:after {
				width: 100%;
			}
		}
	}

  .box-anchor-buttons,
  .box-new-products-buttons {
		//padding-top: 10px;
		text-align: center;
  }

	.btn-anchor {

		color: $color-pink;
		background-color: transparent;
		border-color: $color-pink;
		position: relative;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;

		&:after {
			content: "";
			width: 0%;
			height: 100%;
			top: 0;
			left: 0;
			background: $color-pink;
			position: absolute;
			z-index: -1;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
		}

		&:hover {

			background-color: $color-pink;
			color: $color-white;
			border-color: $color-pink;
			&:after {
				width: 100%;
			}
		}
	}
}

.wrapper-product-section {

	position: relative;

	.on-left-narrow {

		transform: skew(0deg, $prod-archive-screw-angle-neg);

		.unskew {

			transform: skew(0deg, $prod-archive-screw-angle-pos);
		}
	}

	.on-left-tall {

		transform: skew(0deg, $prod-archive-screw-angle-pos);

		.unskew {

			transform: skew(0deg, $prod-archive-screw-angle-neg);
		}
	}

	.background-container {

		position: relative;

		&.bg-helles-rosa {

			background-color: $color-helles-rosa;
		}

		&.bg-white {

			background-color: $color-white;
		}
	}

	&.shift-to-top-first {

		margin-top: $prod-archive-shift-to-top-first;
	}

	&.shift-to-top {

		margin-top: $prod-archive-shift-to-top;
	}

	.product-section-scale {

		min-height: 300px;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.box-section-gallery {

		position: relative;
		z-index: 4;
	}

	// .column-image {

	// 	position: relative;
	// }

	// .column-image {

	// 	margin-bottom: 15px;
	// }

	// .headline-category-main,
	// .headline-category-sub,
	// .caption-bottom-image {

	// 	color: $color-brown;
	// 	text-align: center;
	// }

	// .headline-category-main {

	// 	@include font-size(45px);
	// }

	// .headline-category-sub {

	// 	@include font-size(35px);
	// 	padding-top: 20px;
	// }

	// .caption-bottom-image {

	// 	@include font-size(20px);
	// }

	// .img-section-left,
	// .img-section-right {

	// 	position: absolute;
	// 	width: 300px;
	// 	height: auto;
	// }

	// .img-section-left {

	// 	left: 0;
	// 	bottom: 20%;
	// }

	// .img-section-right {

	// 	right: 0;
	// 	top: 20%;
	// }

}
