@charset "utf-8";

@include breakpoint(large) {

	.wrapper-menu-main {

		padding-top: $padding-top-menu-main-medium;
		text-align: center;

		@include breakpoint(large) {
			//padding-top: $padding-top-menu-main-large;

			@include breakpoint(landscape) {
				padding-top: $padding-top-menu-main-large-landscape;
			}
		}

		a {

			@include font-size($font-size-menu-main);
			font-family: $font-family-menu-main;
			font-weight: $regular;

			@include breakpoint(medium only) {

				@include font-size($font-size-menu-main-medium);
			}

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $color-white;
				visibility: hidden;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
				-webkit-transition: all 0.3s ease-in-out 0s;
				transition: all 0.3s ease-in-out 0s;
			}
			&:hover {
				&:before {
					visibility: visible;
					-webkit-transform: scaleX(1);
		  			transform: scaleX(1);
				}
			}
		}

		// UL first level:
		.nav-main {

			position: relative;
			list-style: none;
			margin: 0;
			padding: 0;

			@include breakpoint(medium) {
        padding-left: $padding-left-offset-center-nav-medium-only;
			}

			@include breakpoint(medium) {
				@include breakpoint(landscape) {
          padding-left: $padding-left-offset-center-nav-large-landscape;
				}
			}
		}

		// LI first level:
		.nav-main-item {

			position: relative;
			display: inline-block;
			margin: 0;
			margin-right: 25px;
			cursor: pointer;

			&.current-menu-item,
			&.current_page_item,
			&.current-menu-ancestor,
			&.current-menu-item,
			&.current-menu-parent,
			&.current-page-ancestor {

				> a {
					color: $color-menu-main-hover;
          font-weight: $medium;

          &:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
              transform: scaleX(1);
          }
				}
			}

			&:hover {

				> a {

					color: $color-menu-main-hover;
				}
			}

			//&:last-of-type {
			&:last-child {

				margin-right: 0;
			}
		}

		// A HREF first level:
		.nav-main-link {

			display: inline-block;
			padding-top: 10px;
			padding-bottom: 10px;
			color: $color-menu-main-normal;
			line-height: 1;
			text-decoration: none;
		}

		.nav-drop {

			display: none;
			position: absolute;
			top: 30px;
			left: -10px;
			z-index: 1;
			background-color: rgba($menu-bg-drop, $menu-bg-drop-opacity);
			padding-bottom: 10px;
			padding-top: 4px;
		}

		.nav-drop-item {

			display: block;
			line-height: 16px;
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 12px;
			cursor: pointer;
			cursor: hand;
			min-width: 250px;

			a {

				color: $color-menu-main-normal;
			}

			&.current-menu-item,
			&.current_page_item,
			&.current-menu-ancestor,
			&.current-menu-item,
			&.current-menu-parent,
			&.current-page-ancestor,
			&:hover {

				a {

					color: $color-menu-main-hover;
				}
			}
		}

		.nav-drop-link {

			display: block;
			padding-top: 10px;
			color: $color-menu-main-normal;
			text-decoration: none;
		}

		@-webkit-keyframes fade-in-menuitem {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}

		@keyframes fade-in-menuitem {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}

		ul li:hover > ul,
		ul li ul:hover {

			display: block;
			-webkit-animation: fade-in-menuitem .3s linear forwards;
			animation: fade-in-menuitem .3s linear forwards;
		}

		@include breakpoint(medium only) {

			@include breakpoint(portrait) {

			}

			@include breakpoint(landscape) {

			}
		}
	}
}

.wrapper-menu-main {
  @include breakpoint(small up) {
    &.mobile {
      margin-top: 40px;
      ul.nav-main {
        li {
          padding: 0.75rem 0;
          text-align: center;
          a {
            color: $color-menu-main-normal;
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
