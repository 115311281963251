// sass-lint:disable no-duplicate-properties
/* open-sans-regular - latin_cyrillic */
//	@font-face {
//		font-family: 'Open Sans';
//		font-style: normal;
//		font-weight: 400;
//		src: url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.eot');
//		// FIX ME: Build issue - will not be copied in build mode
//		/* IE9 Compat Modes */
//		// src: local('Open Sans'), local('OpenSans'),
//		src: url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//		url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
//		url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
//		url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//		url('#{$font-path}/open-sans-v13-latin_cyrillic-regular.svg#OpenSans') format('svg');
//		/* Legacy iOS */
//	}

// 100 - thin
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 100;
	src: url('#{$font-path}/Brandon_thin_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 100;
	src: url('#{$font-path}/Brandon_thin.otf') format('opentype');
}

// 300 - light
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 300;
	src: url('#{$font-path}/Brandon_light_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 300;
	src: url('#{$font-path}/Brandon_light.otf') format('opentype');
}

// 400 - regular
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 400;
	src: url('#{$font-path}/Brandon_reg_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 400;
	src: url('#{$font-path}/Brandon_reg.otf') format('opentype');
}

// 500 - medium
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 500;
	src: url('#{$font-path}/Brandon_med_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 500;
	src: url('#{$font-path}/Brandon_med.otf') format('opentype');
}

// 700 - bold
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 700;
	src: url('#{$font-path}/Brandon_bld_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 700;
	src: url('#{$font-path}/Brandon_bld.otf') format('opentype');
}

// 900 - black
@font-face {
	font-family: Brandon;
	font-style: italic;
	font-weight: 900;
	src: url('#{$font-path}/Brandon_blk_it.otf') format('opentype');
}
@font-face {
	font-family: Brandon;
	font-style: normal;
	font-weight: 900;
	src: url('#{$font-path}/Brandon_blk.otf') format('opentype');
}
